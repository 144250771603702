import LinkedNode from "./LinkedNode.js";
import { pieceValue } from "./constants.js";

// An array of nodes representing the squares that are attacked by the context square
class AttackArray {
    constructor(pieces) {
        this.pieces = pieces; // [LinkedNode, LinkedNode, LinkedNode]
      }

    static deepCopy(obj) {
        if (obj === null) {
            return obj;
        }
        const newObj = new AttackArray();
        newObj.pieces = [];

        obj.pieces.forEach(node => {
            let nodeCopy = LinkedNode.deepCopy(node);
            newObj.pieces = [ ...newObj.pieces, nodeCopy ];
        })

        return newObj;
    }

    static popArrayAtIndex(arr, i) {
        let nextNode = arr.pieces[i];
        
        nextNode = LinkedNode.popNode(nextNode);

        if (!nextNode) {
            arr.pieces.splice(i, 1);
        } else {
            arr.pieces[i] = nextNode;
        }
        return arr;
    }

    static getIndexofMinValue(arr) {
        if ([arr.pieces.length == 0]) return 0;

        let minValue = 0;
        let minIndex = 0;

        for (let i = 0; i < arr.length; i++) {
            if (arr.pieces[i].pieceValue < minValue) {
                minIndex = i;
                minValue = arr.pieces[i].pieceValue;
            }
        }
        return minIndex;
    }

    // Control type 1: Empty square: A side attacks/defends the square more times than the enemy attacks/defends that square
    // Control type 2: A piece occupies the square, and the enemy would lose material if they took it
    // Contested type 1: Empty square: Same number of attackers as defenders onto the square. Unclear who would be at an advantage if they move a piece into that square
    // Contested type 2: A side could gain material by launching an attack, even if they don't end up controlling that square

    static getWinnerOfSquare(wArray, bArray, whiteTotal, blackTotal, piece, prevWVal, prevBVal) {
        
        // Base case, we have reached down to where one side doesn't have any pieces left
        if (!wArray.pieces.length || !bArray.pieces.length) {
            if (!piece) {
                if (wArray.pieces.length) return 'w'; // Control type 1
                if (bArray.pieces.length) return 'b'; // Control type 1
                else return 'c'; // Contested type 1
            } else {
                if (piece.color == 'w') {
                    if (wArray.pieces.length && blackTotal && (blackTotal < whiteTotal + pieceValue(piece.type) - prevWVal)) return 'c'; // Contested type 2
                    if (bArray.pieces.length && (blackTotal <= whiteTotal + pieceValue(piece.type) - prevWVal)) return 'b';
                    else return 'w'; // Control type 2
                } else {
                    if (bArray.pieces.length && whiteTotal && (whiteTotal < blackTotal + pieceValue(piece.type) - prevBVal)) return 'c'; // Contested type 2
                    if (wArray.pieces.length && (whiteTotal <= blackTotal + pieceValue(piece.type) - prevBVal)) return 'w';
                    else return 'b'; // Control type 2
                }
            }
        }

        let whiteMinIndex = this.getIndexofMinValue(wArray);
        let blackMinIndex = this.getIndexofMinValue(bArray);

        let wMinVal = wArray.pieces[whiteMinIndex].pieceValue;
        let bMinVal = bArray.pieces[blackMinIndex].pieceValue;

        let newwArray = this.popArrayAtIndex(wArray, whiteMinIndex);
        let newbArray = this.popArrayAtIndex(bArray, blackMinIndex);

        return this.getWinnerOfSquare(newwArray, newbArray, whiteTotal + wMinVal, blackTotal + bMinVal, piece, wMinVal, bMinVal);
    }
}

export default AttackArray;