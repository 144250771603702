import { Chess }  from 'chess.js';
import { isPinned } from './constants.js';

// Takes an input game, square, and attacking square and returns a list  ['a1', 'a2'] of all battery pieces  
export function getBatteryPieceSquares(game, square, attackSquare) {
    let pieceColor = game.get(square).color;
    let pieceType = game.get(square).type;

    if (pieceType == 'n' || pieceType == 'k') return [];

    let ray = getRay(attackSquare, square);

    return searchForBatteryPieces(game, ray, getPieceSearchType(pieceType, square, attackSquare), pieceColor, square);
}

function searchForBatteryPieces(game, ray, pieces, color, prevSquare) {
    // console.log('entered searchForBatteryPieces', 'ray', ray, 'pieces', pieces, 'color', color, 'prevSquare', prevSquare);
    if (!ray.length) return []; // If empty, exit

    const pieceOnSquare = game.get(ray[0]);

    if (pieceOnSquare && pieceOnSquare.color != color) return []; // If enemy piece, exit
    if (pieceOnSquare && !pieces.includes(pieceOnSquare.type)) return []; // If not the right piece, exit
    if (pieceOnSquare && isPinned(game, ray[0], prevSquare)) return []; // If piece pinned, exit

    let returnArray = pieceOnSquare ? [ray[0]] : [];
    let recursiveArray = searchForBatteryPieces(game, ray.slice(1), pieces, color, ray[0]);

    return [...returnArray, ...recursiveArray];
}

function getPieceSearchType(type, square, attackSquare) {
    switch (type) {
        case 'p': return ['b', 'q'];
        case 'b': return ['b', 'q'];
        case 'r': return ['r', 'q'];
        case 'q':
            if (determineRelation(square, attackSquare) == 'diagonal') {
                return ['b', 'q'];
            } else if (determineRelation(square, attackSquare) == 'vertical') {
                return ['r', 'q'];
            } else {
                return null;
            }
        default: return null;
    }
}

// Takes an input square/type/color and returns a list of list of squares ['a1', 'a2'] of the battery ray behind it
function getRay(square1, square2) {
    // Parse square coordinates
    let file1 = square1.charCodeAt(0) - 'a'.charCodeAt(0);  // Convert file to index (0 to 7)
    let rank1 = parseInt(square1[1], 10) - 1;                // Convert rank to index (0 to 7)
    let file2 = square2.charCodeAt(0) - 'a'.charCodeAt(0);
    let rank2 = parseInt(square2[1], 10) - 1;

    let squares = [];

    // Determine direction (dx, dy)
    let dx = Math.sign(file2 - file1);
    let dy = Math.sign(rank2 - rank1);

    // Move from square2 towards direction
    let currentFile = file2 + dx;
    let currentRank = rank2 + dy;

    while (currentFile >= 0 && currentFile <= 7 && currentRank >= 0 && currentRank <= 7) {
        squares.push(String.fromCharCode(currentFile + 'a'.charCodeAt(0)) + (currentRank + 1));
        currentFile += dx;
        currentRank += dy;
    }

    return squares;
}

function determineRelation(square1, square2) {
    // Convert square strings to coordinates
    let file1 = square1.charCodeAt(0) - 'a'.charCodeAt(0); // Convert 'a'-'h' to 0-7
    let rank1 = parseInt(square1[1]) - 1; // Convert '1'-'8' to 0-7

    let file2 = square2.charCodeAt(0) - 'a'.charCodeAt(0);
    let rank2 = parseInt(square2[1]) - 1;

    // Check if squares are diagonal
    if (Math.abs(file1 - file2) === Math.abs(rank1 - rank2)) {
        return 'diagonal';
    }

    // Check if squares are vertical or horizontal
    if (file1 === file2 || rank1 === rank2) {
        return 'vertical';
    }

    // If neither diagonal nor vertical
    return 'neither';
}
