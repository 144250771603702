import React from 'react';

// Function to update FEN based on piece removal and movement
export const newFEN = (currentFEN, squareX, squareY) => {

  // Parse the FEN string
  const [boardState, turn, castling, enPassant, halfMoves, fullMoves] = currentFEN.split(' ');

  // Function to get the board representation from FEN
  const getBoardFromFEN = (fen) => {
    const board = [];
    const rows = fen.split('/');

    rows.forEach(row => {
      const rowArray = [];
      for (let i = 0; i < row.length; i++) {
        if (!isNaN(row[i])) {
          const numEmptySquares = parseInt(row[i]);
          for (let j = 0; j < numEmptySquares; j++) {
            rowArray.push(null); // represent empty squares as null
          }
        } else {
          rowArray.push(row[i]);
        }
      }
      board.push(rowArray);
    });

    return board;
  };

  // Function to convert board representation back to FEN string
  const getFENFromBoard = (board) => {
    let fen = '';

    board.forEach(row => {
      let emptyCount = 0;
      row.forEach(square => {
        if (square === null) {
          emptyCount++;
        } else {
          if (emptyCount > 0) {
            fen += emptyCount;
            emptyCount = 0;
          }
          fen += square;
        }
      });

      if (emptyCount > 0) {
        fen += emptyCount;
      }

      fen += '/';
    });

    return fen.slice(0, -1); // remove the trailing '/'
  };

  // Convert FEN to board representation
  const board = getBoardFromFEN(boardState);

  // Determine ranks and files for squares x and y
  const fileX = squareX.charCodeAt(0) - 'a'.charCodeAt(0);
  const rankX = 8 - parseInt(squareX[1]);
  const fileY = squareY.charCodeAt(0) - 'a'.charCodeAt(0);
  const rankY = 8 - parseInt(squareY[1]);

  // Ensure valid coordinates
  if (rankX < 0 || rankX > 7 || fileX < 0 || fileX > 7 ||
      rankY < 0 || rankY > 7 || fileY < 0 || fileY > 7) {
    return currentFEN; // return original FEN if invalid coordinates
  }

  // Move piece from square x to square y
  const pieceToMove = board[rankX][fileX];
  board[rankX][fileX] = null; // remove piece from square x
  board[rankY][fileY] = pieceToMove; // place piece from square x to square y

  // Convert board back to FEN string
  const newBoardState = getFENFromBoard(board);

  // Construct and return the new FEN string
  return `${newBoardState} ${turn} ${castling} ${enPassant} ${halfMoves} ${fullMoves}`;
};