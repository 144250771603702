import { Chess, SQUARES } from 'chess.js';
import AttackArray from './AttackArray.js';
import LinkedNode from './LinkedNode.js';
import { pieceValue, isPinned } from './constants.js';
import { getBatteryPieceSquares } from './BatteryHelper.js';

export function buildControlMap(game) {
    let controlMap = []; // For each square, what squares does it attack?
    
    // Initialize a blank array representing the squares attacking the current square
    SQUARES.forEach(square => {
        controlMap.push({
            square: square,
            whiteAttackers: new AttackArray([]),
            blackAttackers: new AttackArray([]), 
            control: 'n'
        });
    });

    // Calculate which squares the piece at each square is attacking
    SQUARES.forEach(square => {
        const index = controlMap.findIndex(item => item.square === square);

        let whiteAttackers = game.attackers(square, 'w'); // ['a1' , 'a2']

        // Filter out the attackers for pins/illegal moves
        whiteAttackers = whiteAttackers.filter(origin => {
            const pieceType = game.get(origin).type;
            if (pieceType == 'k') return true;
            if (pieceType != 'k') return !isPinned(game, origin, square);
        });

        // Create a LinkedNode and generate an AttackArray
        whiteAttackers.forEach(aSquare => {
            let newNode = new LinkedNode(aSquare, pieceValue(game.get(aSquare).type), null);
            controlMap[index].whiteAttackers.pieces.push(newNode); 
        });

        let blackAttackers = game.attackers(square, 'b'); 

        blackAttackers = blackAttackers.filter(origin => {
            const pieceType = game.get(origin).type;
            if (pieceType == 'k') return true;
            if (pieceType != 'k') return !isPinned(game, origin, square);

        });

        blackAttackers.forEach(aSquare => {
            let newNode = new LinkedNode(aSquare, pieceValue(game.get(aSquare).type), null);
            controlMap[index].blackAttackers.pieces = [ ...controlMap[index].blackAttackers.pieces, newNode ];     
        });
    });

    // Add battery pieces to control
    controlMap.forEach(squareObject => {
        const i = controlMap.findIndex(item => item.square === squareObject.square);

        controlMap[i].whiteAttackers.pieces.forEach(node => {
            let pointer = node;
            let batteryArr = getBatteryPieceSquares(game, node.square, squareObject.square);

            batteryArr.forEach(batSquare => {
                let pV = pieceValue(game.get(batSquare.square).type);
                let newNode = new LinkedNode(batSquare, pV, null);

                pointer.next = newNode;
                pointer = newNode;
            })
        });

        controlMap[i].blackAttackers.pieces.forEach(node => {
            let pointer = node;
            let batteryArr = getBatteryPieceSquares(game, node.square, squareObject.square);

            batteryArr.forEach(batSquare => {
                let pV = pieceValue(game.get(batSquare).type);
                let newNode = new LinkedNode(batSquare, pV, null);

                pointer.next = newNode;
                pointer = newNode;
            })
        });
    });

    return controlMap;
}
