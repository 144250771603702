import React, { useState } from 'react';
import { BsChevronDown, BsChevronRight } from 'react-icons/bs';

import './ToggleButton.css';

const ToggleButton = ({ buttonText, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleOpen} className="toggle-button">
        {isOpen ? <BsChevronDown /> : <BsChevronRight />}
        {buttonText}
      </button>
      {isOpen && <div className="toggle-content">{children}</div>}
    </div>
  );
};

export default ToggleButton;