import { Chess, SQUARES }  from 'chess.js';
import { newFEN } from './ChessFENUpdater.js';


export const NEUTRAL_COLOR = "rgba(56, 226, 12, 0.69)";
export const CONTEST_COLOR = "rgba(231, 46, 53, 0.9)";
export const WHITE_COLOR = "rgba(248, 185, 34, 0.69)";
export const DARK_COLOR = "rgba(46, 127, 231, 0.9)";

export function pieceValue(piece) {
    switch(piece) {
        case 'p':
            return 1;
        case 'n':
            return 3;
        case 'b':
            return 3;
        case 'r':
            return 5;
        case 'q':
            return 9;
        case 'k':
            return 1000;
        default:
            return 0;
    }
}

// Flags to ignore: b (pawn push of two squares), k (kingside castling), q (queenside castling)
// If piece == 'p', ignore any moves that don't contain "e" or "c"
export const isAttackingMove = (move) => {
    if (!move.flags.includes('b') 
        && !move.flags.includes('k') 
        && !move.flags.includes('q') 
        && !(move.piece.includes('p') && (move.flags.includes('e') || move.flags.includes('c')))) {
        return true;
    } else {
        return false;
    }
}

export function isPinned(game, square, attackSquare) {
    // King can't be pinned
    if (game.get(square).type == 'k') return false;
    const currentPlayer = game.get(square).color;

    // Generate FEN for if the piece is at the attack square
    let tempFEN = newFEN(game.fen(), square, attackSquare);

    try {
        const tempGame = new Chess(tempFEN);
        let kingSquare = '';
    
        SQUARES.forEach(square => {
            if (game.get(square).type == 'k' && game.get(square).color == currentPlayer) {
                kingSquare = square;
            }
        });
    
        if (tempGame.isAttacked(kingSquare, currentPlayer === 'w' ? 'b' : 'w')) {
            return true;
        }
        return false;
    } catch {
        return false;
    }
}

export const MORPHY_PGN = `[Event "Paul Morphy - Duke Karl Count Isouard (1858.??.??)"]
[Site "Paris (France)"]
[Date "1858.??.??"]
[Round "?"]
[White "Paul Morphy"]
[Black "Duke of Brunswick and Count Isouard"]
[Result "1-0"]

1. e4 e5 2. Nf3 d6 3. d4 Bg4 4. dxe5 Bxf3 5. Qxf3 dxe5 6. Bc4 Nf6 7. Qb3 Qe7 8.
Nc3 c6 9. Bg5 b5 10. Nxb5 cxb5 11. Bxb5+ Nbd7 12. O-O-O Rd8 13. Rxd7 Rxd7 14.
Rd1 Qe6 15. Bxd7+ Nxd7 16. Qb8+ Nxb8 17. Rd8# 1-0`;

export const KASPAROV_PGN = `[Event "It (cat.17)"]
[Site "Wijk aan Zee (Netherlands)"]
[Date "1999.??.??"]
[Round "?"]
[White "Garry Kasparov"]
[Black "Veselin Topalov"]
[Result "1-0"]

1. e4 d6 2. d4 Nf6 3. Nc3 g6 4. Be3 Bg7 5. Qd2 c6 6. f3 b5 7. Nge2 Nbd7 8. Bh6
Bxh6 9. Qxh6 Bb7 10. a3 e5 11. O-O-O Qe7 12. Kb1 a6 13. Nc1 O-O-O 14. Nb3 exd4
15. Rxd4 c5 16. Rd1 Nb6 17. g3 Kb8 18. Na5 Ba8 19. Bh3 d5 20. Qf4+ Ka7 21. Rhe1
d4 22. Nd5 Nbxd5 23. exd5 Qd6 24. Rxd4 cxd4 25. Re7+ Kb6 26. Qxd4+ Kxa5 27. b4+
Ka4 28. Qc3 Qxd5 29. Ra7 Bb7 30. Rxb7 Qc4 31. Qxf6 Kxa3 32. Qxa6+ Kxb4 33. c3+
Kxc3 34. Qa1+ Kd2 35. Qb2+ Kd1 36. Bf1 Rd2 37. Rd7 Rxd7 38. Bxc4 bxc4 39. Qxh8
Rd3 40. Qa8 c3 41. Qa4+ Ke1 42. f4 f5 43. Kc1 Rd2 44. Qa7 1-0`;

export const KARPOV_PGN = `[Event "Ch World (match)"]
[Site "Moscow (Russia)"]
[Date "1985.??.??"]
[Round "?"]
[White "Anatoly Karpov"]
[Black "Garry Kasparov"]
[Result "0-1"]

1. e4 c5 2. Nf3 e6 3. d4 cxd4 4. Nxd4 Nc6 5. Nb5 d6 6. c4 Nf6 7. N1c3 a6 8. Na3
d5 9. cxd5 exd5 10. exd5 Nb4 11. Be2 Bc5 12. O-O O-O 13. Bf3 Bf5 14. Bg5 Re8 15.
Qd2 b5 16. Rad1 Nd3 17. Nab1 h6 18. Bh4 b4 19. Na4 Bd6 20. Bg3 Rc8 21. b3 g5 22.
Bxd6 Qxd6 23. g3 Nd7 24. Bg2 Qf6 25. a3 a5 26. axb4 axb4 27. Qa2 Bg6 28. d6 g4
29. Qd2 Kg7 30. f3 Qxd6 31. fxg4 Qd4+ 32. Kh1 Nf6 33. Rf4 Ne4 34. Qxd3 Nf2+ 35.
Rxf2 Bxd3 36. Rfd2 Qe3 37. Rxd3 Rc1 38. Nb2 Qf2 39. Nd2 Rxd1+ 40. Nxd1 Re1+ 0-1`;

export const TEST_PGN = `[Event "Live Chess"]
[Site "Chess.com"]
[Date "2024.06.14"]
[Round "?"]
[White "elartesan"]
[Black "joogbro"]
[Result "0-1"]
[ECO "B13"]
[WhiteElo "1233"]
[BlackElo "1272"]
[TimeControl "300"]
[EndTime "21:04:32 PDT"]
[Termination "joogbro won by checkmate"]

1. e4 c6 2. Nf3 d5 3. exd5 cxd5 4. d4 Nc6 5. Nc3 Bf5 6. Bb5 e6 7. Bxc6+ bxc6 8.
Ne5 Qb6 9. Be3 Qxb2 10. Bd2 Qb7 11. g4 Bg6 12. f4 h6 13. Qf3 Be4 14. Nxe4 dxe4
15. Qxe4 Nf6 16. Qf3 Nd5 17. c4 Qb2 18. Rd1 Qxd4 19. cxd5 cxd5 20. Bc3 Qa4 21.
O-O Bc5+ 22. Kh1 O-O 23. f5 Qxa2 24. g5 hxg5 25. Rf2 Qxf2 26. Qxf2 Bxf2 27. Rf1
Be3 28. Nf3 Bf4 29. h4 gxh4 30. Nxh4 Bg5 31. Nf3 Bh6 32. Rg1 Kh8 33. f6 g6 34.
Ne5 Rac8 35. Rg3 Bf4 36. Rh3+ Kg8 37. Bd4 Rc1+ 38. Kg2 Rc2+ 39. Kf3 Bxe5 40.
Bxe5 Ra2 41. Bd6 Rc8 42. Bf4 Ra3+ 43. Kg4 Rxh3 44. Kxh3 a5 45. Kg4 a4 46. Be5
Ra8 47. Kf4 a3 48. Ke3 a2 49. Kd4 a1=Q+ 50. Kc5 Qxe5 51. Kc6 Qxf6 52. Kd7 Qd8+
53. Kc6 Qc8+ 54. Kb5 Rb8+ 55. Ka5 Qc1 56. Ka6 Qa1# 0-1`;