import React from 'react';
import { MORPHY_PGN, KASPAROV_PGN, KARPOV_PGN } from './constants.js';
import { Chess, validateFen } from 'chess.js';
import './App.css';

function Options({ handleInput }) {

    function handleInputGame(e) {
        console.log('handleInputGame');
        const game = new Chess();

        try {
            game.loadPgn(e.target.value);
            handleInput(e.target.value, true);
        } catch (error) {
            try {
                const testFEN = validateFen(e.target.value);
                handleInput(e.target.value, false);
            } catch (error) {
                console.error('Error caught:', error.message);
                return;
            }
        }

    }

    return (
        <div>
            <div className="optionsTitle">Load Game</div>
            <h4>Make moves, or...</h4>
            <h5>Load a sample game:</h5>
            <button className="moveButton" onClick={() => { handleInput(MORPHY_PGN, true) }}>Morphy vs. Allies, Paris Opera 1858</button>
            <button className="moveButton" onClick={() => { handleInput(KASPAROV_PGN, true) }}>Kasparov vs. Topalov 1999</button>
            <button className="moveButton" onClick={() => { handleInput(KARPOV_PGN, true) }}>Karpov vs. Kasparov 1985</button>
            <h5>Load a game from FEN/PGN:</h5>
            <textarea className="textBox" onChange={handleInputGame} />
        </div>
    );
}

export default Options;