import React from "react";
import ToggleButton from './ToggleButton';
import ExampleBoard from "./ExampleBoard";
import {CONTEST_COLOR, WHITE_COLOR} from './constants';

import './About.css';

// Control type 1: Empty square: A side attacks/defends the square more times than the enemy attacks/defends that square
// Control type 2: A piece occupies the square, and the enemy would lose material if they took it
// Contested type 1: Empty square: Same number of attackers as defenders onto the square. Unclear who would be at an advantage if they move a piece into that square
// Contested type 2: A side could gain material by launching an attack, even if they don't end up controlling that square

function About() {
    const example_1_fen = `k1r5/3n4/8/8/3P3b/1N2B3/5Q2/4K3 w - - 0 1`;
    const example_1_explanation = `White attacks square c three times, via the knight on b3, the pawn on d4, and the bishop on e3 via battery. Although the queen is part of the battery, it does not attack c5 due to the pin by the bishop on h4.
    Black only attacks square c5 twice, and thus white has full control of the square.`;
    const example_2_fen = `k1r5/3n4/8/2P5/3P4/8/8/4K3 w - - 0 1`;
    const example_2_explanation = `White defends c5 once, whereas black attacks c5 twice, but white still has solid control of c5 because black would lose material via 1. ... Rxc5 or 1. ... Nxc5.`;
    const example_3_fen = `k7/6b1/8/8/2p5/2B5/1P6/7K w - - 0 1`;
    const example_3_explanation = `The white pawn on b2 and the black bishop on g7 both attack c3, but it is unclear who benefits. 
    After 1. ... c4 2. bxc3 Bxc3, Black occupies b3, but the material exchange is even and the bishop is loose.`;
    const example_4_fen = `k7/6b1/8/8/8/2Q5/1P1P4/7K w - - 0 1`;
    const example_4_explanation = `The white queen on c3 is defended more times than it is attacked, but black would stand to benefit from exchanging the bishop for the queen.
    Black does not win control of the square, but the c3 square is still contested because white is in danger.`;

    var e_1_updatedSquares = {};
    e_1_updatedSquares['c5'] = { boxShadow: `inset 0 0 1px 6px ${WHITE_COLOR}` };

    var e_4_updatedSquares = {};
    e_4_updatedSquares['c3'] = { boxShadow: `inset 0 0 1px 6px ${CONTEST_COLOR}` };

    return (
        <div className="aboutPage">
            <h2>What is Chess Heat Map?</h2>
            <p>Chess Heat Map is a visualization tool that shows how each side controls the board as the game progresses.</p>
            <p>Squares are categorized into 4 buckets: Neutral, White, Black, and Contested.</p>

            <h2>Square Categorization</h2>
            <p><b>Neutral</b> &mdash; Neither white nor black has vision on the square.</p>
            <p><b>White/Black</b> &mdash; Squares that are "controlled" by either White/Black.</p>
            <ul>
                <li><p>If a square is <b>empty</b>, the square is controlled by the side that attacks/defends the square more times than the enemy attacks/defends the square. See <b>Example 1</b>.</p></li>
                <li><p>If a square is <b>occupied</b>, the square is controlled by a side if the enemy would lose material by attacking it. See <b>Example 2</b>.</p></li>
            </ul>
            <p><b>Contested</b> &mdash; Squares where it is unclear who solely controls the square.</p>
            <ul>
                <li><p>If a square is <b>empty</b>, the square is contested if the # of attackers = # of defenders. See <b>Example 3</b>.</p></li>
                <li><p>If a square is <b>occupied</b>, the square is contested if a side would gain material by launching an attack, even if they don't end up controlling that square. See <b>Example 4</b>.</p></li>
            </ul>
            
            <h2>Examples</h2>
            <ToggleButton buttonText="Example 1">
                <ExampleBoard example_fen={example_1_fen} explanation={example_1_explanation} moveSquares={e_1_updatedSquares} />
            </ToggleButton>
            <ToggleButton buttonText="Example 2">
                <ExampleBoard example_fen={example_2_fen} explanation={example_2_explanation} moveSquares={e_1_updatedSquares} />
            </ToggleButton>
            <ToggleButton buttonText="Example 3">
                <ExampleBoard example_fen={example_3_fen} explanation={example_3_explanation} moveSquares={e_4_updatedSquares}/>
            </ToggleButton>
            <ToggleButton buttonText="Example 4">
                <ExampleBoard example_fen={example_4_fen} explanation={example_4_explanation} moveSquares={e_4_updatedSquares} />
            </ToggleButton>

            <div className="trailer">
                <br></br>
                <hr></hr>
                <p className="devTag">Developed by Arthur Pan - 2024</p>
            </div>
        </div>
    );
}

export default About;