import React from 'react';
import { Chessboard } from 'react-chessboard';
import './ExampleBoard.css';


function ExampleBoard({ example_fen, explanation, moveSquares }) {
    const boardWidth = (window.innerWidth < 768) ? window.innerWidth * 0.4 : Math.min(window.innerWidth * 0.30, window.innerHeight * 0.50);

    return (
        <div className="exampleWrapper">
            <p>{explanation}</p>
            <div className="boardContainer" style={{ '--half-vh': `${boardWidth}px` }}>
            <Chessboard
                position={example_fen}
                boardWidth={boardWidth}
                customDarkSquareStyle={{ backgroundColor: "rgba(136, 138, 136, 0.69)" }}
                customLightSquareStyle={{ backgroundColor: "rgba(231, 232, 231, 0.69)" }}
                customNotationStyle={{ color: "#000", fontWeight: 'bold' }}
                customSquareStyles={{ ...moveSquares }}
            />
            </div>

            
        </div>
    );
}

export default ExampleBoard;