export default class LinkedNode {
    constructor(square, value, nextNode) {
        this.square = square;
        this.pieceValue = value;
        this.next = nextNode;
    }

    static popNode(node) {
        if (!node.next) return null;
        return node.next;
    }

    static deepCopy(obj) {
        if (obj === null) {
            return obj;
        }

        const newObj = new LinkedNode(obj.square, obj.pieceValue, this.deepCopy(obj.next));

        return newObj;
    }

}
