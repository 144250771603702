import { Routes, Route, Outlet, Link } from "react-router-dom";
import AnalysisBoard from "./AnalysisBoard";
import About from './About';
import './App.css';

function App() {
  return (
    <div className="wrapper">
      <div className="header">
        <h1><Link to="/" className="homeLink">Chess Heat Map</Link></h1>
      </div>
      <div className="bodyContainer">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<AnalysisBoard />} />
            <Route path="analysisBoard" element={<AnalysisBoard />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <nav>
        <ul class="horizontal-list">
          <li>
            <Link to="/analysisBoard" className="noStyleLink">Analysis Board</Link>
          </li>
          <li>
            <Link to="/about" className="noStyleLink">About</Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;