import React, { useState, useEffect } from 'react';
import { Chess, DEFAULT_POSITION, SQUARES } from 'chess.js';
import { Chessboard } from 'react-chessboard';
import MoveHistory from './MoveHistory.js';
import { NEUTRAL_COLOR, WHITE_COLOR, DARK_COLOR, CONTEST_COLOR } from './constants.js';
import LoadGame from './LoadGame.js';
import { parse } from '@mliebelt/pgn-parser';
import GameStats from './GameStats.js';
import AttackArray from './AttackArray.js';
import { buildControlMap } from './ControlMap.js';

import './App.css';
import './AnalysisBoard.css';

function AnalysisBoard() {
    const [game, setGame] = useState(new Chess());

    const [moveSquares, setMoveSquares] = useState({});
    const [squareCounts, setSquareCounts] = useState({ neutral: 0, light: 0, dark: 0, contested: 0});
    const [gameTags, setGameTags] = useState({});
    const [moveCounter, setMoveCounter] = useState(0);
    const [loadedMoves, setLoadedMoves] = useState([]);
    const [boardOrientation, setBoardOrientation] = useState('white');
    const [loadedPGN, setLoadedPGN] = useState('');

    const halfViewportHeight = (window.innerWidth < 768) ? window.innerWidth * 0.75 : Math.min(window.innerWidth * 0.47, window.innerHeight * 0.67);
    const [boardWidth, setBoardWidth] = useState(halfViewportHeight); 


    function handleInput(input, isPGN) {
        if (!isPGN) { 
            game.load(input);
            calculateBoard();
        } else {
            setLoadedPGN(input);
            let parsedMoves = parse(input, { startRule: "game" }).moves;
            setLoadedMoves(parsedMoves);

            let gameTags = parse(input, { startRule: "game" }).tags;
            setGameTags(gameTags);

            game.load(DEFAULT_POSITION);
            calculateBoard();
        }
    }

    function calculateBoard() {
        let controlMap = buildControlMap(game);
        highlightSquaresNew(controlMap);
    }

    function highlightSquaresNew(controlMap) {
        controlMap.forEach(squareObject => {


                if (squareObject.whiteAttackers.pieces.length || squareObject.blackAttackers.pieces.length) {
                    let pieceAtSquare = game.get(squareObject.square);
                    let copiedwAttackers = AttackArray.deepCopy(squareObject.whiteAttackers);
                    let copiedbAttackers = AttackArray.deepCopy(squareObject.blackAttackers);

                    const winner = AttackArray.getWinnerOfSquare(copiedwAttackers, copiedbAttackers, 0, 0, pieceAtSquare, 0, 0);
        
                    squareObject.control = winner;

                } else {
                    squareObject.control = game.get(squareObject.square).color;
                }
        });

        var updatedSquares = {};
        let [whiteCount, blackCount, contestedCount] = [0, 0, 0];

        SQUARES.forEach(square => {
            updatedSquares[square] = { boxShadow: `inset 0 0 1px 5px ${NEUTRAL_COLOR}` };
        });


        controlMap.forEach(s => {
            if (s.control == 'w') {
                updatedSquares[s.square] = { boxShadow: `inset 0 0 1px 6px ${WHITE_COLOR}` };
                whiteCount += 1;
            }
            if (s.control == 'b') {
                updatedSquares[s.square] = { boxShadow: `inset 0 0 1px 6px ${DARK_COLOR}` };
                blackCount += 1;
            }
            if (s.control == 'c') {
                updatedSquares[s.square] = { boxShadow: `inset 0 0 1px 6px ${CONTEST_COLOR}` };
                contestedCount += 1;
            }
        });

        setMoveSquares(updatedSquares);

        setSquareCounts({ 
            neutral: 64 - whiteCount - blackCount - contestedCount,
            light: whiteCount, 
            dark: blackCount,
            contested: contestedCount
        });
    }

    function handleNextMove() {
        if (!loadedMoves.length || moveCounter >= loadedMoves.length) return;
        game.move(loadedMoves[moveCounter].notation.notation);
        setMoveCounter(moveCounter + 1);
        calculateBoard();
    }

    function handlePreviousMove() {
        if (moveCounter <= 0) return;

        reloadGame(moveCounter - 1);
        setMoveCounter(moveCounter - 1);
        calculateBoard();
    }

    function handleStartMove() {
        game.load(DEFAULT_POSITION);
        setMoveCounter(0);
        calculateBoard();
    }

    function handleEndMove() {
        game.loadPgn(loadedPGN);
        setMoveCounter(loadedMoves.length);
        calculateBoard();
    }

    function handleFlipBoard() {
        boardOrientation == 'white' ? setBoardOrientation('black') : setBoardOrientation('white');
    }

    function reloadGame(toMoveNumber) {
        const temp = new Chess();

        for (var i = 0; i < toMoveNumber; i++) {
            temp.move(loadedMoves[i].notation.notation);
        }
        game.loadPgn(temp.pgn());
    }

    function makeAMove(move) {
        const gameCopy = new Chess();
        gameCopy.loadPgn(game.pgn());
        const result = gameCopy.move(move);

        game.move(move)

        setLoadedPGN(game.pgn());
        setMoveCounter(moveCounter + 1);

        let parsedMoves = parse(game.pgn(), { startRule: "game" }).moves;
        setLoadedMoves(parsedMoves);
        calculateBoard();
        return result; 
      }
    

    function handlePieceDrop(sourceSquare, targetSquare) {
        try {
            makeAMove({ from: sourceSquare, to: targetSquare, promotion: "q" });
        }  catch(error) {
            console.error('Error caught:', error.message);
            return;
        }
    }

    function handleResetBoard() {
        game.load(DEFAULT_POSITION);

        setMoveSquares(null);
        setMoveCounter(0);
        setBoardOrientation('white');
        setSquareCounts({ neutral: 0, light: 0, dark: 0, contested: 0});
        setLoadedPGN(null);
    }
    
    function handleTestFunction() {
        game.load('4r1k1/2bn4/8/4P3/3B4/2Q5/8/2K5 w - - 0 1');
        calculateBoard();
    } 
    function getBoardWidth() {

        return 800;
    }

    useEffect(() => {

        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') handleNextMove();
            if (event.key === 'ArrowLeft') handlePreviousMove();
            if (event.key === 'ArrowUp') handleStartMove();
            if (event.key === 'ArrowDown') handleEndMove();
        };

        window.addEventListener('keydown', handleKeyDown);

        const handleResize = () => {
            const newWidth = (window.innerWidth < 768) ? window.innerWidth * 0.75 : Math.min(window.innerWidth * 0.47, window.innerHeight * 0.67);

            setBoardWidth(newWidth);
          };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('resize', handleResize);

        };
    }, [moveSquares, loadedMoves, moveCounter, game]);

    return (
        <div className="body">
            <div className="outerBoardWrapper">
                <div className="innerBoardWrapper">
                    <div className="boardContainer" style={{ '--half-vh': `${boardWidth}px` }}>
                        <Chessboard
                            position={game.fen()}
                            boardWidth={boardWidth}
                            boardOrientation={boardOrientation}
                            customSquareStyles={{ ...moveSquares }}
                            customDarkSquareStyle={{ backgroundColor: "rgba(136, 138, 136, 0.69)" }}
                            customLightSquareStyle={{ backgroundColor: "rgba(231, 232, 231, 0.69)" }}
                            customNotationStyle={{ color: "#000", fontWeight: 'bold' }}
                            onPieceDrop={handlePieceDrop}
                        />
                    </div>
                    <div className="statsContainer">
                        {gameTags.White &&
                            <h4>White: {gameTags.White} {gameTags.WhiteElo} vs. Black: {gameTags.Black} {gameTags.BlackElo}</h4>
                        }
                        <GameStats squareCounts={squareCounts} />
                    </div>
                </div>
            </div>
            {loadedPGN && 
            <div className="options">
                <div className="optionsTitle">Moves</div>
                <MoveHistory inputPGN={loadedPGN} moveCounter={moveCounter} />
                <div className="navigationOptions">
                    <button className="moveButton" onClick={handleStartMove}>&#124;&#60;</button>
                    <button className="moveButton" onClick={handlePreviousMove}>&#60;</button>
                    <button className="moveButton" onClick={handleNextMove}>&#62;</button>
                    <button className="moveButton" onClick={handleEndMove}>&#62;&#124;</button>
                </div>
                <div className="optionsTitle">Options</div>
                <button className="moveButton" onClick={handleResetBoard}>Reset Board</button>
                <button className="moveButton" onClick={handleFlipBoard}>Flip Board</button>

            </div>
            }
            { !loadedPGN && 
            <div className="options">
                <LoadGame loadedPGN={loadedPGN} handleInput={handleInput} />
            </div>
            }
        </div>
    );
}

export default AnalysisBoard;