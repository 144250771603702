import React, { useRef, useEffect } from 'react';
import './MoveHistory.css';
import { parse } from '@mliebelt/pgn-parser';

function MoveHistory({ inputPGN, moveCounter }) {
    const tableRef = useRef(null);

    useEffect(() => {
        if (!tableRef.current || window.innerWidth < 768) return;

        const tableContainer = tableRef.current;
        const parentContainer = tableContainer.parentElement;

        const observerConfig = {
            root: parentContainer,
            threshold: 0.5 // Intersection observer threshold for visibility
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && entry.target.classList.contains('highlighted-cell')) {
                    // Scroll the parent container to bring the cell into view
                    entry.target.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerConfig);

        // Observe all elements with the specific CSS class within the table container
        const cells = tableContainer.querySelectorAll('.highlighted-cell');
        cells.forEach(cell => observer.observe(cell));

        // Clean up observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, [moveCounter]);


    if (!inputPGN) return;

    let parsedMoves = parse(inputPGN, { startRule: "game" }).moves;
    let parsedMoveArray = [];

    for (var i = 0; i < parsedMoves.length; i++) {
        parsedMoveArray.push(parsedMoves[i].notation.notation);
    }

    const chunkArray = (arr, size) => {
        let result = [];
        for (let i = 0; i < arr.length; i += size) {
            result.push(arr.slice(i, i + size));
        }
        return result;
    };

    const pairs = chunkArray(parsedMoveArray, 2);
    
    const shouldHighlightCell = (rowIndex, colIndex) => {
        return rowIndex === Math.floor((moveCounter - 1) / 2) && colIndex === (moveCounter - 1) % 2;
    };

    return (
        <div ref={tableRef} className="table-container">
            <table>
                <tbody>
                    {pairs.map((pair, index) => (
                        <tr key={index} >
                            <td className="move-column">{index + 1}.</td>
                            {pair.map((item, idx) => (
                                <td key={idx} className={shouldHighlightCell(index, idx) ? 'highlighted-cell' : ''}>
                                    {item}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

}

export default MoveHistory;