import React from 'react';

const DynamicTextWithColorBox = ({ text, color, count }) => {
  const boxStyle = {
    display: 'inline-block',
    width: '15px',
    height: '15px',
    backgroundColor: color,
    marginRight: '10px', // Adjust spacing between box and text as needed
    verticalAlign: 'middle', // Align the box vertically with the text
  };

  return (
    <div>
      <span style={boxStyle}></span>
      <span>{text}</span>
      <span>{count}</span>
    </div>
  );
};

export default DynamicTextWithColorBox;