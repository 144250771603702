import React from 'react';
import { NEUTRAL_COLOR, WHITE_COLOR, DARK_COLOR, CONTEST_COLOR } from './constants.js';
import DynamicTextBox from './DynamicTextBox.js';

function GameStats({ squareCounts }) {
    return (
        <div>
            <div className="statsBox">
                <DynamicTextBox text="Neutral: " color={NEUTRAL_COLOR} count={squareCounts.neutral} />
                <DynamicTextBox text="Contested: " color={CONTEST_COLOR} count={squareCounts.contested} />
                <DynamicTextBox text="White: " color={WHITE_COLOR} count={squareCounts.light} />
                <DynamicTextBox text="Black: " color={DARK_COLOR} count={squareCounts.dark} />
            </div>
        </div>
    ); 
}

export default GameStats;